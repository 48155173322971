import { ApiService } from './api-services';

export default class AuthService {
	static baseUrl = ['/api/auth'];

	static async signUp(credentials) {
		const res = await ApiService.post(
			`${AuthService.baseUrl[0]}/sign-up`,
			credentials
		);
		return res;
	}

	static async login(credentials) {
		const res = await ApiService.post(
			`${AuthService.baseUrl[0]}/login`,
			credentials
		);
		return res;
	}

	static async loginWithGoogle(credentials) {
		const res = await ApiService.post(
			`${AuthService.baseUrl[0]}/login-with-google`,
			credentials
		);
		return res;
	}

	static async logout() {
		const res = await ApiService.post(`${AuthService.baseUrl[0]}/logout`);
		return res;
	}

	static async recover(data) {
		const res = await ApiService.post(
			`${AuthService.baseUrl[0]}/recover`,
			data
		);
		return res;
	}

	static async changePassword(data) {
		const res = await ApiService.post(
			`${AuthService.baseUrl[0]}/reset-password`,
			data
		);
		return res;
	}
}
