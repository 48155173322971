import React, { useEffect } from 'react';
import { Box, Container, Grid } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ExpandMore, Repeat } from '@mui/icons-material';
import LanguageSelectorMain from '../../../component/LanguageSelectorMain';
import { useForm } from 'react-hook-form';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import api from '../../../utils/axios';
import toWav from 'audiobuffer-to-wav';
import { io } from 'socket.io-client';
import LanguageSelectorMobile from 'component/LanguageSelectorMobile';
import stripe from 'assets/stripe.png';
import { createNotification } from 'utils/create-notification';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// eslint-disable-next-line no-undef
const socket = io(process.env.REACT_APP_BASE_URL);
// const socket = io('http://localhost:3000');

const TextTranslation = () => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [translation, setTranslation] = React.useState('');
	const [transcription, setTranscription] = React.useState('');
	const [mediaRecorder, setMediaRecorder] = React.useState(null);
	const [audio, setAudio] = React.useState(null);
	const [fullTranscription, setFullTranscription] = React.useState('');
	const [tempTranscription, setTempTranscription] = React.useState('');
	const [textAreaValue, setTextAreaValue] = React.useState('');
	const [value, setValue] = React.useState('1');
	const [rightvalue, setRightValue] = React.useState('2');
	const [selectedLang, seSelectedLang] = React.useState({
		sourceLang: 'English',
		targetLang: 'English',
	});
	const [timerId, setTimerId] = React.useState(null);
	const [languageCode, setLanguageCode] = React.useState({
		sourceLangCode: 'Auto',
		targetLangCode: 'en',
	});
	console.log(transcription);

	const { control, watch, getValues } = useForm();
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const righthandleChange = (event, newValue) => {
		setRightValue(newValue);
	};

	const handleInputChange = (e) => {
		const inputText = e.target.value;
		setTextAreaValue(inputText);
		if (timerId) {
			clearTimeout(timerId);
		}
		const newTimerId = setTimeout(() => {
			makeTranslationRequest(inputText);
		}, 1000); // 3000 ms = 3 seconds

		setTimerId(newTimerId);
	};

	const handleSpeakerClick = () => {
		console.log('hello');
		const audioElement = new Audio(`data:audio/mp3;base64,${audio}`);
		audioElement.play();
	};

	const handleLangCode = (val) => {
		val?.type === 'sourceLanguage' &&
			setLanguageCode({ ...languageCode, sourceLangCode: val?.code });
		val?.type === 'targetLanguage' &&
			setLanguageCode({ ...languageCode, targetLangCode: val?.code });
	};

	useEffect(() => {
		let targetLang = getValues('targetLanguage');
		let sourceLang = getValues('sourceLanguage');
		if (targetLang?.value || sourceLang?.value) {
			seSelectedLang({
				sourceLang: sourceLang?.label,
				targetLang: targetLang?.label,
			});
			setLanguageCode({
				sourceLangCode: sourceLang ? sourceLang?.value : 'Auto',
				targetLangCode: targetLang ? targetLang?.value : 'en',
			});
			if (targetLang?.label && targetLang?.label != selectedLang?.targetLang)
				setRightValue('2');
			if (sourceLang?.label && sourceLang?.label != selectedLang?.sourceLang)
				setValue('2');
		}
	}, [watch()?.sourceLanguage, watch()?.targetLanguage]);

	useEffect(() => {
		const handleTranscription = (data) => {
			if (!data.results[0].isFinal) {
				setTempTranscription(
					(prev) => data.results[0].alternatives[0].transcript
				);
			} else {
				setFullTranscription((prev) => {
					return prev + data.results[0].alternatives[0].transcript;
				});
				setTempTranscription((prev) => '');
			}
		};

		socket.on('transcription', handleTranscription);

		// Clean up the listener when the component is unmounted
		return () => {
			socket.off('transcription', handleTranscription);
		};
	}, []); // Empty dependency array

	useEffect(() => {
		setTextAreaValue(() => fullTranscription + ' ' + tempTranscription);
		const inputText = fullTranscription + ' ' + tempTranscription;
		console.log('hello hamzaig', fullTranscription);
		if (fullTranscription || tempTranscription) {
			if (timerId) {
				clearTimeout(timerId);
			}
			const newTimerId = setTimeout(() => {
				makeTranslationRequest(inputText);
			}, 1000); // 3000 ms = 3 seconds
			setTimerId(newTimerId);
		}
	}, [fullTranscription, tempTranscription]);

	const handleStart = async () => {
		if (!mediaRecorder) {
			console.log('Start');
			try {
				const mediaStream = await navigator.mediaDevices.getUserMedia({
					audio: true,
					video: false,
				});
				const audioContext = new AudioContext();
				const source = audioContext.createMediaStreamSource(mediaStream);
				const processor = audioContext.createScriptProcessor(1024, 1, 1);

				processor.onaudioprocess = (e) => {
					const inputData = e.inputBuffer;
					const wav = toWav(inputData);
					const wavBuffer = new Uint8Array(wav);
					socket.emit('audioData', wavBuffer.buffer);
				};

				source.connect(processor);
				processor.connect(audioContext.destination);

				setMediaRecorder({ mediaStream, processor });
				socket.emit('startStream', languageCode?.sourceLangCode);
			} catch (err) {
				console.error(err);
			}
		}
	};

	const handleStop = () => {
		console.log('Stop');
		if (mediaRecorder) {
			socket.emit('stopStream');
			mediaRecorder.processor.disconnect();
			mediaRecorder.mediaStream
				.getAudioTracks()
				.forEach((track) => track.stop());
			setMediaRecorder(null);
		}
	};

	const handleToggle = () => {
		if (mediaRecorder) {
			handleStop();
		} else {
			handleStart();
			setTextAreaValue('');
			setTranslation('');
			setFullTranscription('');
			setTempTranscription('');
		}
	};

	useEffect(() => {
		if (
			textAreaValue.split('').length > 1 ||
			translation.split('').length > 1
		) {
			makeTranslationRequest(textAreaValue);
		}
	}, [languageCode]);

	const makeTranslationRequest = async (text) => {
		const sourceLanguage = languageCode?.sourceLangCode;
		const targetLanguage = languageCode?.targetLangCode;
		console.log('Making translation request for:', text);
		setIsLoading(true);
		try {
			const response = await api.post('/translation/text', {
				sourceLanguage,
				targetLanguage,
				text,
			});
			setIsLoading(false);
			setTranslation(response?.data?.translatedText);
			setAudio(response?.data?.audioData);
		} catch (error) {
			setIsLoading(false);
			createNotification('error', 'Error', error?.response?.data?.error);
			console.log('Error in text Translation', error?.response?.data?.error);
		}
	};

	function copyToClipboard() {
		navigator.clipboard
			.writeText(translation)
			.then(() => {
				createNotification('success', 'Success', 'Text copied');
			})
			.catch((err) => {});
	}

	return (
		<>
			<Box className="bg-[#f8f8f8] text-trans">
				<Container className="mt-5">
					<Grid
						container
						className="bg-white rounded-lg shadow-lg"
						lg={12}
						md={12}
					>
						<LanguageSelectorMain
							handleChange={handleChange}
							control={control}
							righthandleChange={righthandleChange}
							value={value}
							setValue={setValue}
							rightvalue={rightvalue}
							setRightValue={setRightValue}
							selectedLang={selectedLang}
							setLanguageCode={handleLangCode}
						/>

						<Grid item md={6} xs={12}>
							<Box className="">
								<div className="relative rounded-md border-0">
									<textarea
										value={textAreaValue}
										onChange={handleInputChange}
										type="text"
										placeholder=""
										style={{ resize: 'none', fontSize: '20px' }}
										autoFocus
										className="bg-white block w-full pl-8 pt-8 border-0 outline-none  h-80 focus:outline-none  focus:ring-0 focus:ring-0 focus:border-transparent"
									/>
									<Tab
										icon={<KeyboardVoiceIcon />}
										iconPosition="start"
										value="4"
										className="w-10 absolute"
										onClick={handleToggle}
										style={{
											color: mediaRecorder ? 'red' : 'inherit',
											//bottom:'40px'
										}}
										// style={{bottom:'40px'}}
									/>
								</div>
							</Box>
						</Grid>

						<LanguageSelectorMobile
							handleChange={handleChange}
							control={control}
							righthandleChange={righthandleChange}
							value={value}
							setValue={setValue}
							rightvalue={rightvalue}
							setRightValue={setRightValue}
							selectedLang={selectedLang}
							setLanguageCode={handleLangCode}
						/>

						<Grid item md={6} xs={12} className="border-l-2 border-grey-100">
							<Box>
								<div className="relative rounded-md border-0">
									<textarea
										type="text"
										style={{ fontSize: '20px', resize: 'none', width: '100%' }}
										placeholder="Translation..."
										className="bg-white block w-full pl-8 pt-8 border-0 outline-none  h-80 focus:outline-none  focus:ring-0 focus:ring-0 focus:border-transparent"
										readOnly
										value={translation}
									/>
									<div className="flex justify-end mr-4 cursor-pointer">
										<ContentCopyIcon onClick={copyToClipboard} />
									</div>
									<Tab
										icon={<VolumeUpIcon />}
										iconPosition="start"
										value="4"
										className="w-10 absolute"
										onClick={handleSpeakerClick}
										style={{ marginBottom: '10px' }}
									/>
									{isLoading && (
										<div className="absolute inset-0 flex items-center justify-center">
											<div className="text-loader"></div>
										</div>
									)}
								</div>
							</Box>
						</Grid>
					</Grid>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default TextTranslation;
