import { Box, Container, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from './loader';
import curveImg from 'assets/curve.png';
import logo from 'assets/logo.png';
import userProfile from 'assets/user-profile.jpeg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { logout } from 'store/user';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const toggleMenu = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const profileInformation = useSelector(
		(state) => state?.user?.profileInformation
	);

	const handleLogout = () => {
		dispatch(logout());
		navigate('/');
	};

	return (
		<>
			{!profileInformation && <Loader />}
			<Box className="bg-[#f8f8f8] ">
				<Box className="bg-white pt-3 pb-3 ">
					<Container
						maxWidth="lg"
						className="d-flex justify-content-between align-items-center profiles-user-image"
					>
						<div>
							<Link to="/">
								<img
									alt="company logo"
									src={logo}
									width="180"
									height="180"
									className="d-inline-block align-top pt-3 pb-3"
								/>
							</Link>
						</div>
						<div className="md:mr-5 sm:mr-0">
							<img
								alt="Profile"
								src={`${
									profileInformation?.profilePicture
										? profileInformation?.profilePicture
										: userProfile
								}`}
								width="50"
								height="50"
								style={{
									borderRadius: '50%',
									cursor: 'pointer',
									zIndex: '111',
									width: '60px',
									height: '60px',
								}}
								onClick={() => toggleMenu()}
								// onClick={() => navigate('/user-portal')}
								className="relative"
							/>
						</div>
					</Container>
				</Box>
				<div className="profile-menu">
					<Menu
						anchorEl={
							isMenuOpen ? document.getElementById('profile-menu') : null
						}
						open={isMenuOpen}
						onClose={toggleMenu}
						className="profile-menu-list"
					>
						<div className="hello_menu">
							<MenuItem
								onClick={() => navigate('/user-portal')}
								className="hell02"
							>
								Profile
							</MenuItem>
							{profileInformation?.role === 'Admin' && (
								<>
									<MenuItem
										onClick={() => {
											toggleMenu();
											navigate('/users-panel');
										}}
									>
										Users
									</MenuItem>
									<MenuItem
										onClick={() => {
											toggleMenu();
											navigate('/agent-portal');
										}}
									>
										Agent Portal
									</MenuItem>
								</>
							)}

							<MenuItem onClick={handleLogout}>Logout</MenuItem>
						</div>
					</Menu>
				</div>
				<Box>
					<img
						src={curveImg}
						className="absolute top-0 right-0 curve-img-mobile"
						// style={{ zIndex: '-111' }}
					/>
				</Box>
			</Box>
		</>
	);
};

export default Header;
