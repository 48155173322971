import { Box, Button, Container, Grid } from '@mui/material';
import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Loader from 'component/loader';
const items = [
	{
		id: 1,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+-+Instant+Document+Translation.pdf',
		title: 'Instant Document Translation',
	},
	{
		id: 2,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+-+Instant+SMS+Translation.pdf',
		title: 'Instant SMS Translation',
	},
	{
		id: 3,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+-+Instant+Subtitle+Translation.pdf',
		title: 'Instant Subtitle Translation',
	},
	{
		id: 4,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+-+Instant+Website+Translation.pdf',
		title: 'Instant Website Translation',
	},
	{
		id: 5,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+-+Supported+Languages.pdf',
		title: 'Supported Languages',
	},
	{
		id: 6,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+Email+Reply+Generator.pdf',
		title: 'Email Reply Generator',
	},
	{
		id: 7,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+Presentation+Deck.pdf',
		title: 'Presentation Deck',
	},
	{
		id: 8,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/LetzChat+Real-Time+Translating+Phone+Call.pdf',
		title: 'Real-Time Translating Phone Call',
	},
	{
		id: 9,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/Product+Descriptions.pdf',
		title: 'Product Descriptions',
	},
	{
		id: 10,
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/marketing-materials/Technical+Documentation+Booklet.pdf',
		title: 'Technical Documentation Booklet',
	},
];
export default function MarketingMaterial() {
	const [loading, setLoading] = useState(false);

	const downloadDocument = async (url, filename) => {
		setLoading(true);
		const response = await fetch(url);
		const blob = await response.blob();
		setLoading(false);
		saveAs(blob, filename);
	};

	return (
		<>
			{loading && <Loader />}
			<Box className="scroll-container">
				<Container>
					<div className="p-8 ">
						{items.map((item) => (
							<div
								key={item.id}
								className="user__card__wrapper tab_wrapper flex justify-between items-center border-2 p-2 mb-5 md:mx-40 sm:mx-0"
							>
								<div className="flex items-center">
									<p className="user__profile__text pl-3">{item.title}</p>
								</div>
								<div className="flex gap-3 tab_button">
									<a href={item?.url} target="_blank" rel="noopener noreferrer">
										<Button
											className="skyblue__button"
											variant="contained"
											sx={{
												paddingLeft: '40px',
												paddingRight: '40px',
											}}
										>
											view
										</Button>
									</a>
									<Button
										variant="outlined"
										className="outline__button"
										onClick={() => downloadDocument(item?.url, item?.title)}
									>
										Download
									</Button>
								</div>
							</div>
						))}
					</div>
				</Container>
			</Box>
		</>
	);
}
