import React, { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import stripe from 'assets/stripe.png';
import { Link, useNavigate } from 'react-router-dom';
import api from 'utils/axios';

import userProfile from 'assets/user-profile.jpeg';
import Loader from 'component/loader';

import SearchIcon from '@mui/icons-material/Search';

const Users = () => {
	const [loading, setLoading] = useState(true);
	const [users, setUsers] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		api
			.get('/auth/get-all-users')
			.then((res) => {
				setLoading(true);
				setUsers(res.data.users);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	}, []);

	return (
		<>
			{loading && <Loader />}
			<Box>
				<Container>
					<Box className="relative bg-white h-full rounded-lg rounded-br-lg shadow-lg ">
						<Grid className="flex justify-between items-center pr-2" md={12}>
							<div className="border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0">
								<h2 className="pt-2 pl-3 pb-3 text-[#6B6B6B] text-lg font-medium">
									Users
								</h2>
							</div>
							<div className="relative">
								<input
									className="border-b-2 border-custom-blue p-1 flex justify-center items-center w-60"
									type="text"
									placeholder="Search user"
								/>
								<div className="absolute right-1 bottom-1">
									<SearchIcon sx={{ color: '#747474' }} />
								</div>
							</div>
						</Grid>
						<div className="scroll-container">
							<div className="pr-20 flex justify-center items-center gap-20 lg:pt-10 md:pt-10 sm:pt-0  user__card grid grid-cols-2 md:mx-40 sm:mx-0 mt-2 pb-12">
								{users &&
									users.map((user) => (
										<div
											key={user._id}
											className="user__card__wrapper flex justify-between items-center border-2 p-3 gap-20"
										>
											<div className="flex items-center">
												<img
													onClick={() => navigate(`/user-view/${user._id}`)}
													className="object-cover rounded-full"
													width={32}
													src={`${
														user?.profilePicture
															? user?.profilePicture
															: userProfile
													}`}
												/>
												<p
													className="user__profile__text pl-3"
													onClick={() => navigate(`/user-view/${user._id}`)}
												>
													{user.firstName} {user.lastName}
												</p>
											</div>
											<div>
												<Link
													to={`/user-view/${user._id}`}
													className="bg-[#6db7f2] hover:bg-blue-400 rounded-2xl px-8 py-2"
												>
													view
												</Link>
											</div>
										</div>
									))}
							</div>
						</div>
					</Box>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default Users;
