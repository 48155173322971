import {
	Box,
	Button,
	Container,
	Drawer,
	List,
	ListItem,
	ListItemText,
	IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const MainHeader = () => {
	const navigate = useNavigate();
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

	const toggleMobileMenu = () => {
		setIsMobileMenuOpen(!isMobileMenuOpen);
	};

	return (
		<>
			<Box className="bg-[#f8f8f8] main-header">
				<div className="bg-white pt-5 pb-5">
					<Container maxWidth="lg" className="profiles-user-image">
						<div>
							<Link to="/">
								<img
									alt=""
									src="/logo.png"
									width="180"
									height="180"
									className="d-inline-block align-top pt-3 pb-3"
								/>
							</Link>
						</div>
						<div className="destop-menu">
							<ul className="flex flex-row menu-list">
								<li className="pl-5 pr-5 text-[#707070]">
									<a href="#">Why LetzChat?</a>{' '}
								</li>
								<li className="pl-5 pr-5 text-[#707070]">
									<a href="">How it works</a>
								</li>
								<li className="pl-5 pr-5 text-[#707070]">
									{' '}
									<a href="">Pricing</a>
								</li>
								<li className="pl-5 pr-5 text-[#707070]">
									<a href="">Our Innovations</a>
								</li>
							</ul>
						</div>
						<div className="destop-menu">
							<Button className="login-btn-header" type="button">
								Login
							</Button>

							<Button
								variant="contained"
								type="button"
								className="get-btn"
								style={{
									zIndex: '111',
								}}
							>
								Get Started For Free!
							</Button>
						</div>

						{/* Mobile Menu */}

						<IconButton
							className="mobile-menu-icon"
							onClick={toggleMobileMenu}
							color="inherit"
							aria-label="Menu"
						>
							<MenuIcon />
						</IconButton>
						<Drawer
							anchor="right"
							open={isMobileMenuOpen}
							onClose={toggleMobileMenu}
							className="mobile-menu"
						>
							<IconButton
								onClick={toggleMobileMenu}
								color="inherit"
								aria-label="Close"
								className="mobile-menu-close"
							>
								<CloseIcon />
							</IconButton>
							<List>
								<ListItem button component="a" href="#" className="menulist">
									<ListItemText primary="Why LetzChat?" />
								</ListItem>
								<ListItem button component="a" href="#" className="menulist">
									<ListItemText primary="How it works" />
								</ListItem>
								<ListItem button component="a" href="#" className="menulist">
									<ListItemText primary="Pricing" />
								</ListItem>
								<ListItem button component="a" href="#" className="menulist">
									<ListItemText primary="Our Innovations" />
								</ListItem>
								<ListItem button component="a" href="#" className="menulist">
									<ListItemText primary="Login" />
								</ListItem>
							</List>
							<Button
								variant="contained"
								type="button"
								className="get-btn-mobile"
								style={{
									zIndex: '111',
								}}
							>
								Get Started For Free!
							</Button>
						</Drawer>
					</Container>
				</div>
			</Box>
		</>
	);
};

export default MainHeader;
