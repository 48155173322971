import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import {
	Box,
	Button,
	Container,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import { ExpandMore, Repeat } from '@mui/icons-material';
import LanguageSelectorMain from '../../../component/LanguageSelectorMain';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowRight,
	faClose,
	faLock,
	faUnlock,
	faUnlockAlt,
	faUnlockKeyhole,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Loader from '../../../component/InfiniteLoader/InfiniteLoader';
import stripe from 'assets/stripe.png';
import Modal from 'component/Modal';
import CheckoutForm from 'component/Checkout';
import { useSelector } from 'react-redux';
import api from 'utils/axios';
import { ApiService } from 'services/api-services';
import SimpleLoader from 'component/loader';

const overlayStyles = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.8)',
	zIndex: 1,
};

const ImageTranslation = () => {
	const [showOverlay, setShowOverlay] = useState(true);
	const [loading, setLoading] = useState('');
	const [simpleLoading, setsimpleLoading] = useState('');
	const [selectedValue, setSelectedValue] = useState('');
	const [value, setValue] = React.useState('1');
	const [rightvalue, setRightValue] = React.useState('2');
	const [selectedFile, setSelectedFile] = useState(null);
	const [open, setOpen] = useState(false);
	const [selectedLang, seSelectedLang] = React.useState({
		sourceLang: 'English',
		targetLang: 'English',
	});
	const [languageCode, setLanguageCode] = React.useState({
		sourceLangCode: 'auto',
		targetLangCode: 'en',
	});
	const { control, watch, getValues } = useForm();
	const { profileInformation } = useSelector((state) => state?.user);

	const handleChangeslect = (event) => {
		setSelectedValue(event.target.value);
	};

	const handleFileInputChange = async (event) => {
		console.log('hamx', event.target.files);
		setSelectedFile(event.target.files[0]);
		event.target.value = '';
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const righthandleChange = (event, newValue) => {
		setRightValue(newValue);
	};

	const handleLangCode = (val) => {
		val?.type === 'sourceLanguage' &&
			setLanguageCode({ ...languageCode, sourceLangCode: val?.code });
		val?.type === 'targetLanguage' &&
			setLanguageCode({ ...languageCode, targetLangCode: val?.code });
	};

	useEffect(() => {
		let targetLang = getValues('targetLanguage');
		let sourceLang = getValues('sourceLanguage');
		if (targetLang?.value || sourceLang?.value) {
			seSelectedLang({
				sourceLang: sourceLang?.label,
				targetLang: targetLang?.label,
			});
			setLanguageCode({
				sourceLangCode: sourceLang ? sourceLang?.value : 'auto',
				targetLangCode: targetLang ? targetLang?.value : 'en',
			});
			if (targetLang?.label && targetLang?.label != selectedLang?.targetLang)
				setRightValue('2');
			if (sourceLang?.label && sourceLang?.label != selectedLang?.sourceLang)
				setValue('2');
		}
	}, [watch()?.sourceLanguage, watch()?.targetLanguage]);

	const handleTranslation = async (event) => {
		const sourceLanguage = languageCode?.sourceLangCode;
		const targetLanguage = languageCode?.targetLangCode;
		if (sourceLanguage && targetLanguage) {
			setLoading(true);
			try {
				const formData = new FormData();
				formData.append('image', selectedFile);
				formData.append('sourceLanguage', sourceLanguage);
				formData.append('targetLanguage', targetLanguage);
				if (selectedValue === 'simple') {
					formData.append('isSimple', true);
				}
				const response = await api.post(
					'https://api.video.letz.chat/api/translation/image',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						},
						responseType: 'blob',
					}
				);

				console.log(response.data);
				// Create a Blob from the response data
				const blob = new Blob([response.data], {
					type: response.headers['content-type'],
				});

				// Create a temporary URL to download the file
				const url = window.URL.createObjectURL(blob);

				// Create an anchor element and programmatically click it to trigger the download
				const link = document.createElement('a');
				link.href = url;
				link.download = 'translated-Image'; // Set the desired file name and extension
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				// Release the temporary URL
				window.URL.revokeObjectURL(url);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.error('error in image translation', error);
			}
		} else {
			console.log('error in image translation');
			setSelectedFile(null);
		}
	};

	const handlePayment = async (paymentMethodId) => {
		const response = await ApiService.post('api/payment/image-subscription', {
			paymentMethod: paymentMethodId,
		});
		if (!response?.response?.data?.error) {
			setOpen(false);
			setShowOverlay(false);
		}
	};

	useEffect(() => {
		if (profileInformation?.imageSubscriptionPayment) {
			setShowOverlay(false);
		}
		if (profileInformation?.servicesPayment?.find((el) => el === 'image')) {
			setShowOverlay(false);
		}
	}, [profileInformation]);

	return (
		<>
			{loading && <Loader loading={loading} />}
			<Box>
				<Container className={`mt-5 ${showOverlay ? 'overlay-box' : ''}`}>
					<LanguageSelectorMain
						handleChange={handleChange}
						control={control}
						righthandleChange={righthandleChange}
						value={value}
						setValue={setValue}
						rightvalue={rightvalue}
						setRightValue={setRightValue}
						selectedLang={selectedLang}
						setLanguageCode={handleLangCode}
					/>

					<Box className="relative bg-white rounded-bl-lg rounded-br-lg shadow-lg box-height ">
						{showOverlay && (
							<div
								style={overlayStyles}
								className="flex justify-center items-center flex-wrap lg:gap-10 md:gap-10 sm:gap-0 overlay-text "
							>
								<div className="secure-img">
									<FontAwesomeIcon
										icon={faUnlockKeyhole}
										className="secure-icon"
									/>
								</div>
								<div>
									<h2
										className="text-white font-bold mb-2 text-6xl subcribe-secure hover:text-custom-blue"
										onClick={() => setOpen(true)}
										style={{ cursor: 'pointer' }}
									>
										Subscribe to gain access
									</h2>
									<p className="text-white text-4xl plans-secure">
										The Unlimited Plan is available at a money rate of $5.00.
									</p>
								</div>
							</div>
						)}

						<Grid className="" md={12}>
							<Box className="absolute top-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<h3 className="text-xl font-semibold font-sans tracking-tight text-[#00000099]">
									Choose An Image
								</h3>
							</Box>
							<Box className="pb-14 absolute top-2/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pb-3">
								<p className=" text-[#00000099] pb-10">
									Uplaod a .jpg , .jpeg , .png{' '}
								</p>
							</Box>
							<Box className="absolute top-2/4 left-1/2 transform -translate-x-1/2 -translate-y-1.5/2">
								<div className="flex flex-row ">
									<div className="flex flex-col pb-4 pt-4 relative">
										<input
											type="file"
											className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
											onChange={handleFileInputChange}
											accept="image/*"
										/>
										<button className="w-full h-full bg-[#6db7f2] text-white font-bold py-4 px-6 rounded-lg ml-2 ">
											{selectedFile
												? selectedFile.name
												: 'Browse your computer'}
										</button>
									</div>
									{selectedFile && (
										<div className="pt-6">
											{/* {showCloseButton && ( */}
											<button
												onClick={() => setSelectedFile(null)}
												className="w-10 h-10 bg-[#EC502F] hover:bg-blue-400 text-white font-bold py-2 px-4 rounded-full ml-5 close-btn-img"
											>
												<FontAwesomeIcon
													icon={faClose}
													className="-ml-1 -mr-1 close-icon-img"
												/>
											</button>
											{/* )} */}
										</div>
									)}
								</div>
								{selectedFile && (
									<div className="flex flex-row justify-center items-center mt-2 -ml-10 pb-20 output-type-btn">
										<FormControl fullWidth variant="outlined">
											<InputLabel htmlFor="select-input">
												Output Type
											</InputLabel>
											<Select
												className="mt-2"
												label="Choose an option"
												value={selectedValue}
												onChange={handleChangeslect}
												inputProps={{
													name: 'select-input',
													id: 'select-input',
												}}
												size="small"
											>
												<MenuItem value="not-simple">Formated</MenuItem>
												<MenuItem value="simple">Simple Text Image</MenuItem>
											</Select>
										</FormControl>
										<button
											className="w-4/5 h-4/5  font-bold py-2 px-4 rounded-lg mt-2 ml-2 translate-btn"
											onClick={handleTranslation}
										>
											Translate
										</button>
									</div>
								)}
							</Box>
						</Grid>
					</Box>
					<Box>
						<div className="text-center pt-10">
							<p className="text-lg text-[#00000099]">
								Secure payment transctions are facilitated through
							</p>
							<div className="flex justify-center items-center">
								<img src={stripe} alt="stripe" width={150} />
							</div>
						</div>
					</Box>
					<div>
						<Modal
							// title={'Checkout'}
							open={open}
							handleClose={() => setOpen(false)}
							bodyTopMargin={1}
							className={styles.ModelContainer}
						>
							{/* <div className="flex items-center justify-center h-screen bg-gray-200"> */}
							<div className="w-96 bg-white rounded p-6 shadow-xl">
								<h2 className="text-2xl font-bold mb-6 text-gray-800">
									Checkout
								</h2>
								<p className="mb-6 text-gray-600">
									Subscribe to our image translation service for only $5.00 per
									month. Unlimited translations, no hidden costs.
								</p>
								<CheckoutForm amount={5.0} handlePayment={handlePayment} />
							</div>
							{/* </div> */}
						</Modal>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default ImageTranslation;
