/* eslint-disable react/prop-types */
import cross from 'assets/cross.svg';
import style from './modal.module.scss';

const Modal = ({
	open,
	children,
	className,
	handleClose,
	title,
	text,
	iconStart,
	iconEnd,
	handleClick,
	type,
	btnClass,
	form,
	loader,
	customHeader,
	bodyTopMargin,
}) => {
	const handleClickWrapper = (event) => {
		event.nativeEvent.stopImmediatePropagation();
		handleClose?.();
	};

	return (
		<>
			{open && (
				<div
					className={`${style.modalWrapper}`}
					onClick={(e) => handleClickWrapper(e)}
				>
					<div
						className={`${style.modalContentWrapper} ${className}`}
						onClick={(e) => e.stopPropagation()}
					>
						{title && (
							<div className={style.fixedClass}>
								<div className={`${style.header} ${customHeader}`}>
									<p>{title}</p>
									<img src={cross} alt="close icon" onClick={handleClose} />
								</div>
							</div>
						)}
						<div
							className={`${style.body} ${className}`}
							style={{
								paddingTop: bodyTopMargin
									? `calc(${bodyTopMargin}px + (39 - 12) * (100vw - 280px) / (2560 - 280))`
									: 'calc(12px + (89 - 12) * (100vw - 280px) / (2560 - 280))',
							}}
						>
							{children}
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Modal;
