/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import { TabContext } from '@mui/lab';
import { Box, Container, Grid, Tab } from '@mui/material';
import React, { useState } from 'react';
import LanguageSelectorHeader from './LanguageSelectorHeader';
import CustomSelect from './CustomSelect';
import languages from '../constants/languages';
import { Repeat } from '@mui/icons-material';

const LanguageSelectorMain = ({
	handleChange,
	control,
	righthandleChange,
	value,
	setValue,
	rightvalue,
	setRightValue,
	selectedLang,
	setLanguageCode,
}) => {
	const [languageSelectFor, setLanguageSelectFor] = useState({
		languageFor: '',
		languageToggle: false,
	});

	const handleLanguageChange = (e) => {
		setLanguageSelectFor({ ...languageSelectFor, languageToggle: false });
	};

	const handleToggleAndLangSelect = (e) => {
		setLanguageSelectFor({
			languageFor: e,
			languageToggle: !languageSelectFor?.languageToggle,
		});
	};
	return (
		<>
			<Grid
				container
				className="relative bg-white rounded-tl-lg rounded-tr-lg  shadow-lg "
				md={12}
			>
				<Grid item md={6} xs={12}>
					<Box className="left-slector">
						<TabContext value={value}>
							<Box className="border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0">
								<LanguageSelectorHeader
									handleChange={handleChange}
									languageSide="sourceLanguage"
									selectedLang={selectedLang?.sourceLang}
									setLanguageToggle={handleToggleAndLangSelect}
									languageToggle={languageSelectFor}
									setLanguageCode={setLanguageCode}
								/>
							</Box>
						</TabContext>
					</Box>
				</Grid>
				{/* <Grid item md={1} xs={12}>
						<Box className="border-b-2 border-grey-100">
							<Tab
								icon={<Repeat />}
								iconPosition="start"
								value="4"
								className="w-10"
							/>
						</Box>
					</Grid> */}
				<Grid item md={6} xs={12}>
					<Box className="right-slector">
						<TabContext value={rightvalue}>
							<Box className="border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0">
								<LanguageSelectorHeader
									handleChange={righthandleChange}
									selectedLang={selectedLang?.targetLang}
									languageSide="targetLanguage"
									setLanguageToggle={handleToggleAndLangSelect}
									languageToggle={languageSelectFor}
									setLanguageCode={setLanguageCode}
								/>
							</Box>
						</TabContext>
					</Box>
				</Grid>
				{languageSelectFor?.languageToggle && (
					<Grid md={12} className="">
						<CustomSelect
							name={languageSelectFor?.languageFor}
							control={control}
							options={languages.map((country) => ({
								label: country.name,
								value: country.code,
							}))}
							placeholder={'Select Language'}
							handleChange={handleLanguageChange}
						/>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default LanguageSelectorMain;
