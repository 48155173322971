import React, { useState } from 'react';
import { TextField, Button, Box, Container, IconButton } from '@mui/material';

import { AddCircle, Delete } from '@mui/icons-material';
import Modal from 'component/Modal';
import CustomSelect from 'component/CustomSelect';
import { useForm } from 'react-hook-form';
import styles from './createInvoice.module.scss';

function createData(title, qty, rate, amount) {
	return { title, qty, rate, amount };
}

export default function CreateInvoice() {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [addItem, setAddItem] = useState(false);
	const [addQty, setAddQty] = useState(false);
	const [open, setOpen] = useState(false);
	const [rows, setRows] = useState([
		createData('item #1', 2, '$100', '$200'),
		createData('item #2', 2, '$100', '$200'),
		createData('item #3', 2, '$100', '$200'),
	]);

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	const handleAdd = (e) => {
		e.preventDefault();
		const newRow = createData(addItem, addQty, '$100', '$200');
		setRows((prevRows) => [...prevRows, newRow]);
		setOpen(false);
	};
	const handlekk = () => {
		setAddItem(true);
		setOpen(true);
		setAddQty(true);
		console.log('kk');
	};

	const { control } = useForm();

	return (
		<>
			<div>
				<Modal
					open={open}
					handleClose={() => {
						setOpen(false);
					}}
					title="Add Items"
					className={styles.ModelContainer}
					// className="invoice_modal"
				>
					<form onSubmit={handleAdd}>
						<div className="mb-5 select-width">
							<label htmlFor="">Items</label>
							<CustomSelect
								placeholder={'Item Name'}
								control={control}
								name={'addItem'}
								options={[
									{ label: 'Image translation', value: 'image translation' },
									{
										label: 'Website translation',
										value: 'website translation',
									},
								]}
								handleChange={() => {
									setAddItem(true);
									setOpen(true);
									setAddQty(true);
								}}
							/>
						</div>
						<div className="select-width mb-10">
							<label htmlFor="">Quantity</label>
							<CustomSelect
								placeholder={'quantity'}
								control={control}
								name={'addQty'}
								options={[{ label: '1', value: '1' }]}
								handleChange={() => {
									setAddItem(true);
									setOpen(true);
									setAddQty(true);
								}}
							/>
						</div>
						<div>
							<Button
								type="submit"
								variant="contained"
								className="skyblue__button"
							>
								Submit
							</Button>
						</div>
					</form>
				</Modal>
			</div>
			<Box>
				<Container className="pt-5">
					<form onSubmit={handleSubmit} className="pt-5 md:mx-40 sm:mx-0">
						<div className="grid grid-cols-2 mb-3">
							<div className="col-span-1">
								<TextField
									value={name}
									onChange={(e) => setName(e.target.value)}
									type="text"
									variant="outlined"
									fullWidth
									required
									placeholder="Name"
									className="input-invoice"
								/>
							</div>
							<div className="col-span-1 flex items-center justify-around gap-5">
								<label className="label__invoice">Invoice#</label>
								<TextField
									value={name}
									onChange={(e) => setName(e.target.value)}
									variant="outlined"
									fullWidth
									type="text"
									required
									placeholder="INV-01"
								/>
							</div>
						</div>
						<div className="grid grid-cols-2 mb-3">
							<div className="col-span-1">
								<TextField
									variant="outlined"
									fullWidth
									required
									type="text"
									placeholder="Address Line"
								/>
							</div>
							<div className="col-span-1 flex items-center justify-around gap-5">
								<label className="label__invoice">Invoice Date</label>
								<TextField variant="outlined" fullWidth required type="date" />
							</div>
						</div>
						<div className="grid grid-cols-2 mb-3">
							<div className="col-span-1">
								<TextField
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									type="email"
									variant="outlined"
									fullWidth
									required
									placeholder="Email Address"
								/>
							</div>
							<div className="col-span-1 flex items-center justify-around gap-5">
								<label className="label__invoice">Due Date</label>
								<TextField
									value={name}
									onChange={(e) => setName(e.target.value)}
									variant="outlined"
									fullWidth
									type="Date"
									required
									placeholder="Due Date"
								/>
							</div>
						</div>
						<div>
							<ul aria-label="customized table" className="w-full pt-14">
								<li>
									<ul className="flex justify-between items-center thead__table rounded-xl mb-5">
										<li className="text-left">Description</li>
										<li className="text-left">QTY</li>
										<li className="text-left">Rate</li>
										<li className="text-left">Amount</li>
										<li className="text-left"></li>
									</ul>
								</li>
								<li>
									{rows.map((row) => (
										<ul
											key={row.name}
											className="flex justify-between items-center border-2 border-grey-200 p-5 mb-3 rounded-xl "
										>
											<li scope="row">{row.title}</li>
											<li className="pl-10">{row.qty}</li>
											<li className="pl-3">{row.rate}</li>
											<li className="pl-3">{row.amount}</li>
											<li>
												<Delete color="action" />
											</li>
										</ul>
									))}
								</li>
							</ul>
						</div>
						<div className="flex justify-between items-center pt-8">
							<div className="flex items-center" onClick={handlekk}>
								<div>
									<IconButton color="primary" aria-label="Add">
										<AddCircle className="add-item-btn" />
									</IconButton>
								</div>
								<div>
									<p>
										<span className="add-items-text cursor-pointer">
											Add Line Items
										</span>
									</p>
								</div>
							</div>
							<div className="flex items-center gap-10">
								<label className="total-title">Subtotal</label>
								<TextField
									type="number"
									variant="outlined"
									fullWidth
									required
									disabled
									placeholder="$600.00"
									className="input-number"
								/>
							</div>
						</div>

						<div className="flex items-center justify-end gap-10 mt-5">
							<label className="total-title">Sales Tax(10%)</label>
							<TextField
								type="number"
								variant="outlined"
								fullWidth
								required
								disabled
								placeholder="$60.00"
								className="input-number"
							/>
						</div>
						<div className="flex items-center justify-end gap-10 mt-5 mb-10">
							<label className="total-title">Total</label>
							<TextField
								type="number"
								variant="outlined"
								fullWidth
								required
								disabled
								placeholder="$660.00"
								className="input-number"
							/>
						</div>
						<div className="mb-10">
							<TextField
								id="my-textarea"
								label="Notes"
								multiline
								fullWidth
								rows={8}
							/>
						</div>
						<div className="flex justify-center items-center">
							<Button
								type="submit"
								variant="contained"
								className="save-invoice "
							>
								Save Invoice
							</Button>
						</div>
					</form>
				</Container>
			</Box>
		</>
	);
}
