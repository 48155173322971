import { createSlice } from '@reduxjs/toolkit';
import AuthService from 'services/auth-services';
import ProfileService from 'services/profile-services';

const initialUser = localStorage.getItem('user')
	? JSON.parse(localStorage.getItem('user'))
	: {};

const slice = createSlice({
	name: 'user',
	initialState: initialUser,
	reducers: {
		signUpSuccess: (state, action) => {
			state.user = action.payload;
			localStorage.setItem('user', JSON.stringify(action.payload));
		},
		loginSuccess: (state, action) => {
			Object.keys(action.payload).forEach((key) => {
				state[key] = action.payload[key];
			});
			localStorage.setItem('user', JSON.stringify(action.payload));
		},
		logoutSuccess: (state, action) => {
			Object.keys(state).forEach((key) => {
				delete state[key];
			});
			localStorage.removeItem('user');
		},
		getProfileInformation: (state, action) => {
			state.profileInformation = action.payload?.user;
		},
		updateProfilePicture: (state, action) => {
			state.profileInformation.profilePicture = action.payload;
		},
		updateProfileInformation: (state, action) => {
			state.profileInformation = action.payload?.user;
		},
		deleteProfilePicture: (state, action) => {
			state.profileInformation.profilePicture = undefined;
		},
	},
});
export default slice.reducer;

const {
	loginSuccess,
	logoutSuccess,
	signUpSuccess,
	getProfileInformation,
	updateProfilePicture,
	deleteProfilePicture,
	updateProfileInformation,
} = slice.actions;

// export const signUp =
// 	({ firstName, lastName, email, password, organizationName }) =>
// 	async (dispatch) => {
// 		try {
// 			const { data } = await AuthService.signUp({
// 				firstName,
// 				lastName,
// 				email,
// 				password,
// 				confirmPassword: password,
// 				organizationName,
// 			});
// 			if (data) {
// 				dispatch(signUpSuccess(data));
// 			}
// 		} catch (e) {
// 			return console.error(e.message);
// 		}
// 	};

export const login =
	({ email, password }) =>
	async (dispatch) => {
		try {
			const { data } = await AuthService.login({
				email,
				password,
			});
			if (data) {
				dispatch(loginSuccess(data));
			}
		} catch (e) {
			return console.error(e.message);
		}
	};

export const loginWithGoogle =
	({ token }) =>
	async (dispatch) => {
		try {
			const { data } = await AuthService.loginWithGoogle({
				token,
			});
			if (data) {
				dispatch(loginSuccess(data));
			}
		} catch (e) {
			return console.error(e.message);
		}
	};

export const updateProfilePictureFunc = (imageBase64) => async (dispatch) => {
	try {
		console.log(imageBase64);
		const { data } = await ProfileService.updateProfilePicture({
			imageBase64,
		});
		if (data) {
			dispatch(updateProfilePicture(data?.profilePicture));
		}
	} catch (e) {
		return console.error(e.message);
	}
};

export const updateProfileInformationAction = (body) => async (dispatch) => {
	try {
		const { data } = await ProfileService.updateProfileInformation({
			...body,
		});
		if (data) {
			dispatch(updateProfileInformation(data));
		}
	} catch (e) {
		return console.error(e.message);
	}
};

export const deleteProfilePictureFunc = () => async (dispatch) => {
	try {
		const { data } = await ProfileService.deleteProfilePicture();
		if (data) {
			dispatch(deleteProfilePicture());
		}
	} catch (e) {
		return console.error(e.message);
	}
};

export const logout = () => async (dispatch) => {
	try {
		dispatch(logoutSuccess());
	} catch (e) {
		return console.error(e.message);
	}
};

export const getProfileInfoAction = () => async (dispatch) => {
	try {
		const res = await ProfileService.getAllProfileInformation();
		dispatch(getProfileInformation(res.data));
	} catch (e) {
		return console.error(e.message);
	}
};
