/* eslint-disable react/prop-types */
import Select from 'react-select';

import React from 'react';
import { useController } from 'react-hook-form';

const CustomSelect = (props) => {
	const {
		field: { onChange, value },
		handleChange,
	} = useController({ control: props.control, name: props.name });

	return (
		<Select
			{...props}
			onChange={(val) => {
				onChange(val), props?.handleChange({ changeFor: props?.name });
			}}
			value={value}
			placeholder={props.placeholder}
			styles={{
				// eslint-disable-next-line no-unused-vars
				control: (base, state) => ({
					border: 0,
					display: 'flex',
					padding: '5px',
					paddingLeft: '20px',
				}),
				valueContainer: () => ({
					padding: '10px 0',
					width: '100%',
					maxHeight: 40,
					display: 'flex',
					alignItems: 'center',
				}),
				multiValue: () => ({
					display: 'flex',
					width: 'fit-content',
					background: 'rgba(76 ,29, 149,0.2)',
					borderRadius: 0,
					padding: '0 5px',
					alignItems: 'center',
					margin: '0 3px',
				}),
				option: (provided, state) => ({
					...provided,
					backgroundColor: state.isSelected ? '#6db7f2' : '#fff',
					color: state.isSelected ? '6db7f2' : 'gray-800',
					borderColor: 'red',
				}),
				dropdownIndicator: () => ({ display: 'none' }),
				indicatorSeparator: () => ({ display: 'none' }),
			}}
			className="bg-[#fff]  border-b-2 border-grey-100 border-t-0 border-r-0 border-l-0 rounded-0"
		/>
	);
};

export default CustomSelect;
