// Loader.js
import React, { useState, useEffect } from 'react';
import './Loader.css';

// eslint-disable-next-line react/prop-types
const Loader = ({ loading }) => {
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		let timer;
		if (loading) {
			timer = setInterval(() => {
				setPercentage((prevPercentage) => (prevPercentage + 1) % 100);
			}, 2000);
		} else {
			setPercentage(0);
		}

		return () => {
			if (timer) {
				clearInterval(timer);
			}
		};
	}, [loading]);

	return (
		<>
			{loading && (
				<div className="loader-container">
					<div className="circular-loader"></div>
					<div className="percentage">{percentage}%</div>
				</div>
			)}
		</>
	);
};

export default Loader;
