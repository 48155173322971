import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
// import { Link } from 'react-router-dom';
import {
	Article,
	Image,
	PeopleAlt,
	Person2,
	Person2Rounded,
	Public,
	TranslateRounded,
} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import './translation.modules.scss';
import { useDispatch, useSelector } from 'react-redux';
import User, { getProfileInfoAction } from 'store/user';

const Translation = () => {
	const [isAdmin, setIsAdmin] = useState(true);
	const dispatch = useDispatch();
	const { profileInformation } = useSelector((state) => state.user);

	useEffect(() => {
		dispatch(getProfileInfoAction());
	}, []);

	return (
		<>
			<Header />
			<Box
				className="bg-gray-100 mb-1  translation-tabs"
				style={{ zIndex: '1000' }}
			>
				<Container>
					<Box className="translate-tabs pt-10  md:flex justify-start gap-2 items-center md:flex-row sm:flex-col ">
						<Box className="tab-1 text-[#6db7f2] hover:text-[#fff ">
							<NavLink
								to="/"
								exact
								activeClassName="active"
								className="tabstyle-1  border border-2 border-[#6db7f2] py-4 px-8 rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] focus:bg-[#6db7f2] focus:text-[#fff] "
							>
								<TranslateRounded
									fontSize="medium"
									style={{ marginRight: '2px' }}
								/>{' '}
								Text
							</NavLink>
						</Box>
						{profileInformation?.services?.find((el) => el === 'image') && (
							<Box className="tab-2 text-[#6db7f2] hover:text-[#fff] ">
								<NavLink
									to="/image-translation"
									activeClassName="active"
									className="tabstyle-2 border border-2 border-[#6db7f2]  py-4 px-8 rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2]  focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<Image
										fontSize="medium"
										style={{ marginRight: '2px', marginBottom: '2px' }}
									/>
									Images
								</NavLink>
							</Box>
						)}
						{profileInformation?.services?.find((el) => el === 'document') && (
							<Box className="tab-3 text-[#6db7f2] hover:text-[#fff] ">
								<NavLink
									to="/doc-translation"
									activeClassName="active"
									className=" tabstyle-3 border border-2 border-[#6db7f2]  py-4 px-8 rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2]  focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<Article
										fontSize="medium"
										style={{ marginRight: '2px', marginBottom: '2px' }}
									/>
									Documents
								</NavLink>
							</Box>
						)}
						{profileInformation?.services?.find((el) => el === 'webpage') && (
							<Box className="tab-4 text-[#6db7f2] hover:text-[#fff]">
								<NavLink
									to="/web-translation"
									activeClassName="active"
									className=" tabstyle-4 border border-2 border-[#6db7f2]  py-4 px-8 rounded-lg text-[#6db7f2] hover:bg-[#6db7f2] hover:text-[#fff] active:bg-[#6db7f2]  focus:bg-[#6db7f2] focus:text-[#fff] "
								>
									<Public
										fontSize="medium"
										style={{ marginRight: '2px', marginBottom: '2px' }}
									/>
									Websites
								</NavLink>
							</Box>
						)}
					</Box>
				</Container>
			</Box>
		</>
	);
};

export default Translation;
