const languages = [
	{ code: 'af', name: 'Afrikaans' },
	{ code: 'sq', name: 'Albanian' },
	{ code: 'am', name: 'Amharic' },
	{ code: 'ar', name: 'Arabic' },
	{ code: 'hy', name: 'Armenian' },
	{ code: 'az', name: 'Azerbaijani' },
	{ code: 'bn', name: 'Bengali' },
	{ code: 'bs', name: 'Bosnian' },
	{ code: 'bg', name: 'Bulgarian' },
	{ code: 'ca', name: 'Catalan' },
	{ code: 'zh', name: 'Chinese' },
	{ code: 'hr', name: 'Croatian' },
	{ code: 'cs', name: 'Czech' },
	{ code: 'da', name: 'Danish' },
	{ code: 'fa', name: 'Dari' },
	{ code: 'nl', name: 'Dutch' },
	{ code: 'en', name: 'English' },
	{ code: 'et', name: 'Estonian' },
	{ code: 'fa', name: 'Farsi' },
	{ code: 'tl', name: 'Filipino' },
	{ code: 'fi', name: 'Finnish' },
	{ code: 'fr', name: 'French' },
	{ code: 'ka', name: 'Georgian' },
	{ code: 'de', name: 'German' },
	{ code: 'el', name: 'Greek' },
	{ code: 'gu', name: 'Gujarati' },
	{ code: 'ht', name: 'Haitian' },
	{ code: 'ha', name: 'Hausa' },
	{ code: 'he', name: 'Hebrew' },
	{ code: 'hi', name: 'Hindi' },
	{ code: 'hu', name: 'Hungarian' },
	{ code: 'is', name: 'Icelandic' },
	{ code: 'id', name: 'Indonesian' },
	{ code: 'ga', name: 'Irish' },
	{ code: 'it', name: 'Italian' },
	{ code: 'ja', name: 'Japanese' },
	{ code: 'kn', name: 'Kannada' },
	{ code: 'kk', name: 'Kazakh' },
	{ code: 'ko', name: 'Korean' },
	{ code: 'lv', name: 'Latvian' },
	{ code: 'lt', name: 'Lithuanian' },
	{ code: 'mk', name: 'Macedonian' },
	{ code: 'ms', name: 'Malay' },
	{ code: 'ml', name: 'Malayalam' },
	{ code: 'mt', name: 'Maltese' },
	{ code: 'mr', name: 'Marathi' },
	{ code: 'mn', name: 'Mongolian' },
	{ code: 'no', name: 'Norwegian' },
	{ code: 'ps', name: 'Pashto' },
	{ code: 'pl', name: 'Polish' },
	{ code: 'pt', name: 'Portuguese' },
	{ code: 'pt', name: 'Portuguese' },
	{ code: 'pa', name: 'Punjabi' },
	{ code: 'ro', name: 'Romanian' },
	{ code: 'ru', name: 'Russian' },
	{ code: 'sr', name: 'Serbian' },
	{ code: 'si', name: 'Sinhala' },
	{ code: 'sk', name: 'Slovak' },
	{ code: 'sl', name: 'Slovenian' },
	{ code: 'so', name: 'Somali' },
	{ code: 'es', name: 'Spanish' },
	{ code: 'sw', name: 'Swahili' },
	{ code: 'sv', name: 'Swedish' },
	{ code: 'ta', name: 'Tamil' },
	{ code: 'te', name: 'Telugu' },
	{ code: 'th', name: 'Thai' },
	{ code: 'tr', name: 'Turkish' },
	{ code: 'uk', name: 'Ukrainian' },
	{ code: 'ur', name: 'Urdu' },
	{ code: 'uz', name: 'Uzbek' },
	{ code: 'vi', name: 'Vietnamese' },
	{ code: 'cy', name: 'Welsh' },
	{ code: 'es-MX', name: 'Spanish(Mexico)' },
	{ code: 'pt-PT', name: 'Portuguese(Portugal)' },
	{ code: 'zh-TW', name: 'Chinese(Traditional)' },
	{ code: 'fa-AF', name: 'Dari' },
	{ code: 'fr-CA', name: 'French(Canada)' },
];

export const liveLanguages = [
	{ code: 'zh-CN', name: 'Chinese, Simplified' },
	{ code: 'en-AU', name: 'English, Australian' },
	{ code: 'en-GB', name: 'English, British' },
	{ code: 'en-US', name: 'English, US' },
	{ code: 'fr-FR', name: 'French' },
	{ code: 'fr-CA', name: 'French, Canadian' },
	{ code: 'de-DE', name: 'German' },
	{ code: 'hi-IN', name: 'Hindi, Indian' },
	{ code: 'it-IT', name: 'Italian' },
	{ code: 'ja-JP', name: 'Japanese' },
	{ code: 'ko-KR', name: 'Korean' },
	{ code: 'pt-BR', name: 'Portuguese, Brazilian' },
	{ code: 'es-US', name: 'Spanish, US' },
	{ code: 'th-TH', name: 'Thai' },
];

export default languages;
