import Translation from 'pages/Translation';
import Login from 'pages/auth/Login';
import UserPortal from 'pages/profile/UserPortal';
import DocumentTranslation from 'pages/translation/document-translation/DocumentTranslation';
import ImageTranslation from 'pages/translation/image-translation/ImageTranslation';
import TextTranslation from 'pages/translation/text-translation/TextTranslation';
import Users from 'pages/translation/users/Users';
import UserView from 'pages/translation/users/UserView';
import WebsiteTranslation from 'pages/translation/website-translation/WebsiteTranslation';
import AgentPortal from 'pages/translation/agent-portal/AgentPortal';

export const privateRoutes = [
	{
		path: '/',
		component: (
			<div>
				<Translation />
				<TextTranslation />
			</div>
		),
	},
	{
		path: '/image-translation',
		component: (
			<div>
				<Translation />
				<ImageTranslation />
			</div>
		),
	},
	{
		path: '/doc-translation',
		component: (
			<div>
				<Translation />
				<DocumentTranslation />
			</div>
		),
	},
	{
		path: '/web-translation',
		component: (
			<div>
				<Translation />
				<WebsiteTranslation />
			</div>
		),
	},
	{
		path: '/user-portal',
		component: <UserPortal />,
	},
];

export const publicRoute = [
	{
		path: '/login',
		component: <Login />,
	},
];

export const adminRoute = [
	{
		path: '/',
		component: (
			<div>
				<Translation />
				<TextTranslation />
			</div>
		),
	},
	{
		path: '/image-translation',
		component: (
			<div>
				<Translation />
				<ImageTranslation />
			</div>
		),
	},
	{
		path: '/doc-translation',
		component: (
			<div>
				<Translation />
				<DocumentTranslation />
			</div>
		),
	},
	{
		path: '/web-translation',
		component: (
			<div>
				<Translation />
				<WebsiteTranslation />
			</div>
		),
	},

	{
		path: '/users-panel',
		component: (
			<div>
				<Translation />
				<Users />
			</div>
		),
	},
	{
		path: '/user-portal',
		component: <UserPortal />,
	},
	{
		path: '/user-view/:userId',
		component: <UserView />,
	},
	{
		path: '/agent-portal',
		component: (
			<div>
				<Translation />
				<AgentPortal />
			</div>
		),
	},
];
