import './App.css';
import Routes from './routes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

const App = () => {
	const [loader, setLoader] = useState(false);
	const { token } = useSelector((state) => state.user);
	return (
		<>
			<Router>
				<Routes token={token} loader={loader} />
			</Router>
		</>
	);
};

export default App;
