import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Provider } from 'react-redux';
import store from './store';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_5FofprIZVNtzt0Lpl5Jow25M');
// const stripePromise = loadStripe(
// 	'pk_test_51MNjxHLowMMxn8HGI1WHijMqhZPmV3tzVVtS7exK2NB3MMeccybI2XPSYFg9WtIlyFDYGWQigO3lkQMFFvDHDqtP00p9wARzNy'
// );
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={store}>
		<Elements stripe={stripePromise}>
			<App />
		</Elements>
		<NotificationContainer />
	</Provider>
);
