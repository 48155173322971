import React, { useEffect } from 'react';
import { Box, Button, Container, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { login, loginWithGoogle } from 'store/user';
import Loader from 'component/loader';
import googleIcon from 'assets/GOOGLE.png';
import rightCurve from 'assets/right-curve.png';
import leftCurve from 'assets/left-curve.png';
import leftImage from 'assets/left-img.png';
import logo from 'assets/logo.png';

// Define validation schema with yup
const schema = yup.object().shape({
	email: yup
		.string()
		.email('Invalid email format')
		.required('Email is required'),
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Minimum 8 Characters'),
});

const Login = () => {
	//for testing
	const [loading, setLoading] = React.useState(false);
	const [isGoogleInitialized, setIsGoogleInitialized] = React.useState(false);
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (data) => {
		setLoading(true);
		await dispatch(login(data));
		setLoading(false);
	};

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.onload = () => {
			window.google.accounts.id.initialize({
				client_id:
					// eslint-disable-next-line no-undef
					process.env.REACT_APP_GOOGLE_CLIENT_ID_CONTINUE_WITH_GOOGLE,
				callback: handleCredentialResponse,
			});
			setIsGoogleInitialized(true);
		};
		document.body.appendChild(script);
	}, []);

	const handleCredentialResponse = async (response) => {
		const token = response.credential;
		setLoading(true);
		await dispatch(loginWithGoogle({ token }));
		setLoading(false);
	};

	const handleLogin = () => {
		console.log('Done');
		setLoading(true);
		window.google.accounts.id.prompt();
		setLoading(false);
	};

	return (
		<>
			{loading && <Loader />}
			<Box className="h-screen">
				<Box>
					<img
						src={rightCurve}
						className="fixed top-0 right-0"
						alt="right-curve"
					/>
				</Box>
				<Box>
					<img
						src={leftCurve}
						className="fixed bottom-0 left-0 z-0"
						alt="left-curve"
						width={500}
					/>
				</Box>
				<Container>
					<div className="flex items-center justify-center h-screen">
						<div className="grid md:grid-cols-2 sm:grid-cols-1">
							<div className="left-wrapper">
								{' '}
								<img src={leftImage} className="relative" alt="left-img" />{' '}
							</div>

							<div className="right-wrapper flex flex-col justify-center items-center">
								<div className="mb-5 logo-img">
									<img
										src={logo}
										className="mb-5 relative"
										width={250}
										alt="logo-img"
									/>
								</div>
								<form onSubmit={handleSubmit(onSubmit)} className="form-signin">
									<div className="mb-5">
										<p className="mb-5 text-md">Sign In </p>
										<TextField
											{...register('email')}
											id="email"
											className="style-input"
											name="email"
											label="Email"
											variant="outlined"
											size="medium"
											error={!!errors.email}
											helperText={errors.email?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: '#6DB7F2',
														},
														'&.Mui-focused fieldset': {
															borderColor: '#6DB7F2',
														},
													},
												},
											}}
										/>
									</div>
									<div className="mb-5">
										<TextField
											{...register('password')}
											id="password"
											className="style-input"
											name="password"
											label="Password"
											type="password"
											size="medium"
											error={!!errors.password}
											helperText={errors.password?.message}
											sx={{
												'& .MuiOutlinedInput-root': {
													'& fieldset': {
														borderColor: 'rgba(126, 139, 182, 0.3);',
														borderRadius: '10px',
														'&:hover fieldset': {
															borderColor: 'green',
														},
														'&.Mui-focused fieldset': {
															borderColor: 'green',
														},
													},
												},
											}}
										/>
									</div>
									<div>
										<p className="text-right mb-5 text-[#6DB7F2] text-md">
											<a href="#">Forgot Password?</a>
										</p>
									</div>

									<div className="mb-5">
										<Button
											variant="contained"
											type="submit"
											className="submit-button"
										>
											Login
										</Button>
									</div>
									{isGoogleInitialized && (
										<Button className="google-link-btn" onClick={handleLogin}>
											<img
												src={googleIcon}
												alt="google-login"
												width={20}
												className="mr-2"
											/>
											Sign Up With Google
										</Button>
									)}
								</form>
							</div>
						</div>
					</div>
				</Container>
			</Box>
		</>
	);
};

export default Login;
