import { Box, Button, Container } from '@mui/material';
import React, { useState } from 'react';
import { saveAs } from 'file-saver';
import Loader from 'component/loader';
const items = [
	{
		id: 1,
		title: 'Full Service MSRP Pricing Plans',
		url: 'https://letz-chat-agent-material.s3.us-east-2.amazonaws.com/price-guides/Full+Service+MSRP+Pricing+Plans.pdf',
	},
];
export default function PricingGuide() {
	const [loading, setLoading] = useState(false);

	const downloadDocument = async (url, filename) => {
		setLoading(true);
		const response = await fetch(url);
		const blob = await response.blob();
		setLoading(false);
		saveAs(blob, filename);
	};
	return (
		<>
			{loading && <Loader />}
			<Box>
				<Container>
					<div className="p-8">
						{items.map((item) => (
							<div
								key={item.id}
								className="user__card__wrapper tab_wrapper flex justify-between items-center border-2 p-2 mb-5 md:mx-40 sm:mx-0"
							>
								<div className="flex items-center">
									<p className="user__profile__text pl-3">{item.title}</p>
								</div>
								<div className="flex gap-3 tab_button">
									<a href={item?.url} target="_blank" rel="noopener noreferrer">
										<Button
											className="skyblue__button"
											variant="contained"
											sx={{
												paddingLeft: '40px',
												paddingRight: '40px',
											}}
										>
											view
										</Button>
									</a>
									<Button
										variant="outlined"
										className="outline__button"
										onClick={() => downloadDocument(item?.url, item?.title)}
									>
										Download
									</Button>
								</div>
							</div>
						))}
					</div>
				</Container>
			</Box>
		</>
	);
}
