import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
	console.log('dev barnch created');

	return (
		<>
			<Box className="bg-custom-blue flex justify-center text-white p-2 relative bottom-0 w-full mt-20">
				©2023 Copyright: Letzchat, Inc. All rights preserved.{' '}
				<Link
					className="mx-1"
					to={'https://www.letzchat.com/terms-of-service.html'}
				>
					Terms of Service
				</Link>{' '}
				|
				<Link
					className="ml-1"
					to={'https://www.letzchat.com/privacy-policy.html'}
				>
					Privacy Policy
				</Link>
			</Box>
		</>
	);
};

export default Footer;
